.container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: var(--text-color-primary);
}

.selectionList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.selection {
  width: 300px;
  max-width: 50%;
  padding: 8px;
  opacity: 0.9;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color-primary);
  font-size: 14px;
  font-weight: 500;
  text-align: left;

  &:hover {
    opacity: 1;
    transition: 0.15s ease;

    .selectionBackground {
      transition: 0.15s ease;
      transform: scale3d(1.04, 1.04, 1.04);
    }
  }

  .selectionBackground {
    width: 100%;
    padding: 40% 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .selectionLabel {
    padding: 8px 0;
    font-weight: 500;
    color: var(--text-color-primary);
  }
}

.searchContainer {
  width: 100%;
  padding: 16px 0;

  input {
    width: 100%;
    flex-grow: 1;
  }
}