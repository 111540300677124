* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
  box-sizing: border-box;
}

body {
  background-color: #171717;
}

.App {
  text-align: center;
  background-color: var(--background-color-primary);
  color: var(--text-color-primary);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}