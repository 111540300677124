.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popupContainer {
  width: 80%;
  height: 80%;
  background: var(--background-color-secondary);
  overflow-y: scroll;
  box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 95%;
    height: 95%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px;
  color: var(--text-color-primary);
  position: sticky;
  top: 0;
  background: var(--background-color-secondary);
  z-index: 1;
}

.body {
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}

.closeIcon {
  cursor: pointer;
}