.container {
    max-width: 80%;
    text-align: left;
    display: flex;

    @media screen and (max-width: 768px) {
        max-width: 95%;
    }

    &.assistant {
        flex-direction: row;

        .messageBubble {
            border-top-left-radius: 0px;
            background-color: var(--background-color-secondary);
            color: var(--text-color-primary);
        }

        .avatar {
            margin-right: 8px;
        }
    }

    &.user {
        margin-left: auto;
        flex-direction: row-reverse;

        .messageBubble {
            border-top-right-radius: 0px;
            background-color: var(--background-color-action-primary);
            color: var(--text-color-action);
        }

        .avatar {
            margin-left: 8px;
        }
    }
}

.messageBubble {
    display: flex;
    align-items: center;
    padding: 12px;
    white-space: pre-wrap;
    background-color: var(--background-color-secondary);
    color: var(--text-color-primary);
    border-radius: 10px;
    box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.15);
    line-height: 22px;
    word-break: break-word;

    p,
    ol,
    ul {
        margin: 8px 0;
    }

    ol,
    ul {
        padding-left: 22px;
    }

    a {
        text-decoration: underline;
        color: var(--background-color-action-primary);
    }
}

.avatar>div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--background-color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;
        font-size: 10px;
    }
}

.avatarBG {
    background-size: contain;
}