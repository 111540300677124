.pageContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.chatContainer {
    height: 100%;
    box-sizing: border-box;
    background-color: var(--background-color-primary);
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 10px;
        padding-top: 50px;
    }
}

.chatBody {
    display: flex;
    height: 100%;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
}

.messagesContainer {
    flex-grow: 1;
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.userSettingsWrapper {
    position: absolute;
    right: 0;
    padding: 10px;
}