.container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    width: 60px;
    height: 60px;
    background-size: contain;
    border-radius: 50%;
}

.text {
    margin-top: 10px;
    font-size: 18px;
    color: var(--text-color-primary);
}