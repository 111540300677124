.collapseButton {
  padding: 16px;
  font-size: 24px;
  position: absolute;
  left: 200px;
  z-index: 10;
  transition: 0.4s ease;
  cursor: pointer;
  color: var(--background-color-action-primary);

  @media screen and (max-width: 768px) {
    display: block;
    background: var(--background-color-secondary);
    left: calc(100% - 42px);
  }

  &.isCollapsed {
    left: 0;
    background: transparent;
  }
}

.container {
  width: 300px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  transition: 0.3s ease;
  background: var(--background-color-secondary);
  box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 80%;
    max-width: 350px;
    height: 100%;
    z-index: 10;

    &.isCollapsed {
      position: absolute;
      left: -100%;
    }
  }

  &.isCollapsed {
    width: 0;
    padding: 0;
    overflow: hidden;

    * {
      white-space: nowrap;
    }
  }

  &.isDisabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.mobileOverlay {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    animation: fadeIn 0.2s ease;
  }

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;

  &.isCollapsed {
    display: none;
  }
}

.chatHistoryContainer {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-align: left;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chatHistoryTitle {
  font-size: 18px;
  color: var(--text-color-primary);
}

.chatLink {
  text-decoration: none;
  transition: 0.2s ease;
  width: 100%;

  >.background {
    width: 85%;
  }

  &.selected {
    >.background {
      width: 100%;
    }
  }
}

.chatLink+.chatLink {
  margin-top: 12px;
  padding-top: 12px;
}

.generalChatBlock {
  position: relative;
  height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #FFF;
  background-size: cover;
  font-weight: 600;

  &::before {
    z-index: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &::after {
    content: "General Chat";
    z-index: 1;
    padding: 8px;
    text-align: center;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}