.container {
  display: flex;
  height: 100%;
  color: var(--text-color-primary);

  a {
    color: var(--text-color-primary);
  }
}

.logo {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.title {
  font-weight: 300;
  font-size: 50px;
  color: var(--text-color-primary);
}

.imageContainer,
.formContainer {
  flex-direction: column;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.formContainer {
  padding: 30px;
}

.imageContainer {
  background-color: var(--background-color-secondary);

  > img {
    width: 100%;
  }
}

.input {
  display: block;
  width: 100%;
  margin: 20px 0;
}

.checkboxContainer {
  margin-bottom: 20px;
}

.checkboxLabel {
  cursor: pointer;
  margin-left: 10px;
}

.form {
  width: 100%;
}

.errorMessage {
  color: #ff4949;
}

.bodyText {
  margin: 0;
  font-size: 24px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 100px;
    flex-direction: column;
  }

  .imageContainer,
  .formContainer {
    width: 100%;
  }

  .imageContainer {
    background-color: transparent;
    > img {
      width: 90%;
    }
  }

  .title {
    font-size: 35px;
  }

  .bodyText {
    font-size: 18px;
  }
}

.loadingWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}