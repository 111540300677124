.container {
    width: 100%;
    display: flex;
    align-items: center;
}

.inputWrapper {
    flex-grow: 1;
    input {
        width: 100%;
    }
}

.buttonWrapper {
    margin-left: 8px;
}

.desktop {
    display: initial;
}

.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: initial;
    }
}