.container {
  padding: 25px;
  .table {
    text-align: left;
    td,th {
      padding: 10px;
      border-bottom: solid 1px var(--input-border-color);
      border-right: solid 1px var(--input-border-color);
    }
    th {
      border-top: solid 1px var(--input-border-color);
    }
    th,td {
      &:first-child {
        border-left: solid 1px var(--input-border-color);
      }
    }
  }
}