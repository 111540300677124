.button {
  outline: none;
  border-radius: 10px;
  padding: 14px 16px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.buttonPrimary {
  border: none;
  background: var(--background-color-action-primary);
  color: var(--text-color-action);
}

.buttonSecondary {
  border: solid 1px var(--background-color-action-primary);
  color: var(--background-color-action-primary);
  background-color: var(--background-color-action-secondary);
}


.button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.fullWidth {
  width: 100%;
}