.container {
    white-space: pre-wrap;
    text-align: left;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    color: var(--text-color-primary);
}

.row {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 10px 0;

    >*+* {
        margin-left: 8px;
    }
}

.email {
    font-size: 13px;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
}

.barWrapper {
    display: flex;
    width: calc(100% - 150px);
}

.bar {
    height: 20px;
    background: var(--background-color-action-primary);
}

.value {
    margin-left: 5px;
}