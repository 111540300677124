.wrapper {
  width: 600px;
  max-width: 90%;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 30px;
  z-index: 100;
}

.container {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  color: #FFF;
  display: flex;
  text-align: left;
  align-items: center;
  animation: slideInBottom 0.5s ease-out;

  &.error {
    background-color: #ff4949;
  }
}

.iconWrapper {
  margin-right: 16px;
  font-size: 20px;
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  margin-bottom: 4px;
}

.description {
  margin: 0;
  font-size: 16px;
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}