.container {
    position: relative;
    color: var(--text-color-primary);
    z-index: 9;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color-action-primary);
    color: var(--text-color-action);
    cursor: pointer;
    font-size: 16px;
}

.dropDownContainer {
    position: absolute;
    background: var(--background-color-secondary);
    color: var(--text-color-primary);
    min-width: 130px;
    right: 5px;
    top: 115%;
    border-radius: 3px;
    text-align: left;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.4);
}

.option {
    cursor: pointer;
    padding: 12px 8px;
    transition: 0.2s ease;
    display: flex;

    i {
        margin-right: 5px;
    }

    &:hover {
        background-color: var(--text-color-primary);
        color: var(--background-color-secondary);
    }
}