body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  --background-color-primary: #f6f7fa;
  --background-color-secondary: #FFF;
  --background-color-tertiary: #FFF;
  --background-color-action-primary: #00a64e;
  --background-color-action-secondary: transparent;
  --text-color-primary: #4c4c4c;
  --text-color-action: #FFF;
  --input-border-color: #4c4c4c;
}

.App.theme-dark {
  --background-color-primary: #171717;
  --background-color-secondary: #232323;
  --background-color-tertiary: #303030;
  --background-color-action-primary: #37dfd5;
  --background-color-action-secondary: transparent;
  --text-color-primary: #cbcbcb;
  --text-color-action: #030202;
  --input-border-color: #4c4c4c;
}