.container {
    background-color: var(--background-color-secondary);
    width: 100%;
    max-width: 550px;
    height: 100%;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 10px;
    padding: 50px;
}

.logo {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    z-index: 1;
}

.input {
    display: block;
    width: 100%;
    margin: 10px;
}

.title {
    font-weight: 300;
    font-size: 50px;
    color: var(--text-color-primary);
}

.errorMessage {
    color: #ff4949;
}

.loadingWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signupLink {
    margin-top: 15px;
    color: var(--text-color-primary);
}